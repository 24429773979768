<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div class="flex flex-wrap align-items marginBottom20">
          <div class="flex align-items marginRight10 marginBottom10">
            <div class="width300">
              <el-input
                v-model="searchData.name"
                placeholder="输入申请人/手机号"
                @keyup.enter.native="handleSearch"
                clearable
                maxlength="20"
              ></el-input>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <div class="width300">
              <el-date-picker
                v-model="searchData.createTime"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleSearch"
              >
              </el-date-picker>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <div class="width200">
              <el-select
                class="width250"
                v-model="searchData.state"
                clearable
                @change="handleSearch"
              >
                <el-option
                  value=""
                  label="请选择状态"
                ></el-option>
                <el-option
                  :value="1"
                  label="待审核"
                ></el-option>
                <el-option
                  :value="2"
                  label="打款失败"
                ></el-option>
                <el-option
                  :value="3"
                  label="审核驳回"
                ></el-option>
                <el-option
                  :value="4"
                  label="已打款"
                ></el-option>
              </el-select>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              class="marginLeft10"
            >查询</el-button>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <el-button
              size="medium"
              type="info"
              icon="el-icon-refresh-right"
              @click="handleReset"
              class="marginLeft10"
            >重置</el-button>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <el-button
              type="primary"
              size="medium"
              icon="el-icon-download"
              class="marginLeft10"
              @click="handleExport"
            >导出</el-button>
          </div>
        </div>
      </template>
      <template
        slot="workCertImages"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          v-if="row.workCertImages"
          @click="onPreview(row)"
        >查看</el-button>
        <el-image
          style="width: 0; height: 0; "
          ref="preview"
          :src="imageList[0]"
          :preview-src-list="imageList"
          v-if="row.workCertImages"
        ></el-image>
        <div v-if="!row.workCertImages">/</div>
      </template>

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <div v-if="row.state === 1">
          <el-button
            type="text"
            size="mini"
            @click="handleAgree(row)"
          >通过</el-button>
          <el-button
            type="text"
            size="mini"
            @click="handleReject(row)"
          >驳回</el-button>
        </div>
        <div v-else>/</div>
      </template>
    </avue-crud>

    <!-- 驳回弹框 -->
    <el-dialog
      v-if="dialogVisibleBh"
      :title="'驳回'"
      :visible.sync="dialogVisibleBh"
      width="500px"
      :before-close="handleCloseBh"
    >
      <avue-form
        ref="formBh"
        v-model="formBh"
        :option="formOptionBh"
        @submit="submitBh"
      >
        <template slot="menuForm">
          <el-button
            icon="el-icon-close"
            @click="handleCloseBh"
          >取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  withdrawList,
  withdrawAgree,
  withdrawRefuse,
} from "@/api/partner/withdraw";
import qs from "qs";
import dayjs from "dayjs";
export default {
  name: "index",
  data() {
    return {
      imageList: [],
      disabled: false,
      searchData: {
        name: "",
        createTime: "",
        state: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "提现审核",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        menu: true,
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "ID",
            prop: "id",
          },
          {
            label: "销售合伙人编号",
            prop: "salePartnerCode",
          },
          {
            label: "申请人姓名",
            prop: "partnerUsername",
          },
          {
            label: "联系方式",
            prop: "partnerPhone",
          },
          {
            label: "提现金额（元）",
            prop: "price",
          },
          {
            label: "申请时间",
            prop: "createTime",
          },
          {
            label: "审批时间",
            prop: "examineTime",
            formatter: function (row, column, cellValue) {
              return cellValue ? cellValue : "/";
            },
          },
          {
            label: "状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state === 1
                ? "待审核"
                : row.state === 2
                ? "打款失败"
                : row.state === 3
                ? "审核驳回"
                : row.state === 4
                ? "已打款"
                : row.state;
            },
          },
          {
            label: "驳回原因",
            prop: "rejectReason",
            formatter: function (row, column, cellValue) {
              return cellValue ? cellValue : "/";
            },
          },
        ],
      },

      // 驳回表单
      dialogVisibleBh: false,
      formBh: {
        id: "",
        rejectReason: "暂不符合申请条件",
      },
      formOptionBh: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: 0,
        column: [
          {
            prop: "rejectReason",
            type: "textarea",
            size: "medium",
            minRows: 3,
            span: 24,
            placeholder: "请输入驳回原因",
            maxRows: 10,
            maxlength: 100,
            rules: [
              {
                required: true,
                message: "请输入驳回原因",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
      if (
        searchForm.searchData?.createTimeStart &&
        searchForm.searchData?.createTimeEnd
      ) {
        this.searchData.createTime = [
          searchForm.searchData?.createTimeStart,
          searchForm.searchData?.createTimeEnd,
        ];
      }
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    // 通过
    handleAgree(row) {
      this.$confirm("确定通过该提现申请吗？", "通过", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        withdrawAgree({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功!");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 驳回
    handleReject(row) {
      this.dialogVisibleBh = true;
      this.formBh = {
        id: row.id,
        rejectReason: "暂不符合申请条件",
      };
    },
    // 驳回关闭
    handleCloseBh() {
      this.dialogVisibleBh = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 重置驳回表单
    resetForm() {
      this.formBh = {
        id: "",
        rejectReason: "暂不符合申请条件",
      };
    },
    // 驳回确定
    submitBh(form, done) {
      withdrawRefuse(form)
        .then((res) => {
          if (res.code === 200) {
            this.dialogVisibleBh = false;
            setTimeout(() => {
              this.resetForm();
            }, 500);
            this.$message.success("操作成功");
            this.onLoad();
            done();
          } else {
            this.$message.error(res.msg);
            done();
          }
        })
        .catch(() => {
          done();
        });
    },
    // 查询
    handleSearch() {
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      this.searchData = {
        name: "",
        createTime: "",
        state: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    onLoad() {
      this.showLoading = true;
      const searchData = {
        name: this.searchData.name,
        state: this.searchData.state,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      const searchForm = {
        path: this.$route.path,
        searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));

      withdrawList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 导出
    handleExport() {
      const searchData = {
        name: this.searchData.name,
        state: this.searchData.state,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      window.open(
        `/api/web/partner/withdraw/export?${qs.stringify(searchData)}`,
        "_self"
      );
    },
  },
};
</script>
